<template>
  <cloud-applications />
</template>

<script>
import CloudApplications from "../../components/solutions/cloud-solutions/CloudApplications.vue";
export default {
  name: "cloud-applications-page",
  components: { CloudApplications },
};
</script>
